<template>
  <div class="the-aside-right">
    <div class="the-aside-right__header">
      <h2 class="the-aside-right__title">
        {{ title }}
        <ElementBadge
          v-if="appliedFiltersCount > 0"
          :value="appliedFiltersCount"
          :max="appliedFilterCountMax"
          type="primary"
          class="the-aside-right__applied-filters-badge"
        />
      </h2>

      <ElementButton
        type="text"
        class="the-aside-right__clear-filters"
        :style="{
          visibility: `${appliedFiltersCount > 0 ? 'visible' : 'hidden'}`
        }"
        @click="clearFilters"
      >
        Clear filters <i class="el-icon-close el-icon-right" />
      </ElementButton>
    </div>

    <div
      ref="filterContainer"
      class="the-aside-right__content"
    >
      <template v-if="hasVisibleAvailableFilters">
        <div
          v-for="(value, name) in filtersToDisplay"
          :key="name"
          class="the-aside-right__filter-group"
        >
          <span class="the-aside-right__label">{{
            parseFilterGroupName(name)
          }}</span>
          <StitchTooltip
            :message="availableFilterSettings[name].disabledMessage"
            :is-disabled="!availableFilterSettings[name].isDisabled"
          >
            <ElementSelect
              v-if="filters[name]"
              v-model="filters[name]"
              placeholder="All"
              filterable
              multiple
              :disabled="availableFilterSettings[name].isDisabled"
              @change="filterIds => onCheckboxChange(name, filterIds)"
            >
              <ElementOption
                v-for="filter in value"
                :key="`${libraryType}-${name}-${filter.id}`"
                :label="filter.label"
                :value="filter.id"
              />
            </ElementSelect>
          </StitchTooltip>
        </div>
      </template>
      <template v-if="libraryType === LIBRARY_TYPE.FABRIC">
        <span class="the-aside-right__label">Weight</span>
        <ElementSlider
          v-model="weightFilterRange"
          range
          :min="minWeight"
          :max="maxWeight"
          :step="DEFAULT_WEIGHT_STEP"
          :format-tooltip="formatWeightTooltip"
          class="the-aside-right__weight-slider"
          @change="onWeightFilterChange"
        />
      </template>

      <div v-else>
        No filters available
      </div>

      <span ref="filterListBottomTrigger" />
    </div>

    <div class="the-aside-right__footer">
      <i
        ref="filterListScrollIndicator"
        class="el-icon-arrow-down"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// canUserAccessWorkspaces
import FeatureFlags from '@/services/featureFlags'
import { FiltersPersistency } from '@/mixins/FiltersPersistency.js'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { FILTER_TYPE } from '@/constants/filterType'
import { USER_ROLE } from '@/constants/roleType'

export default {
  name: 'TheAsideRight',

  mixins: [FiltersPersistency],

  data () {
    return {
      LIBRARY_TYPE,
      FILTER_TYPE,
      title: 'Filters',
      appliedFilterCountMax: 99,
      DEFAULT_WEIGHT_STEP: 1,
      filters: {},
      filterListIntersectionObserver: null,
      minWeight: null,
      maxWeight: null,
      weightFilterRange: []
    }
  },

  computed: {
    ...mapGetters(['getAvailableFilters', 'getAppliedFilters']),

    ...mapGetters({
      libraryType: 'getActiveLibraryType',
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    },

    /**
     * @returns {Array}
     */
    availableFilters () {
      return this.getAvailableFilters()
    },

    /**
     * @returns {boolean}
     */
    canAccessWorkspaces () {
      return FeatureFlags.canUserAccessWorkspaces()
    },

    /**
     * @returns {object}
     */
    visibleAvailableFilters () {
      const filters = this.availableFilters || {}

      // Division and Group is removed because it is selected on the header
      const filtersToRemove = [FILTER_TYPE.GROUP_ID, FILTER_TYPE.DIVISION_ID]

      if (this.isVendorUser) {
        filtersToRemove.push(FILTER_TYPE.VENDOR_COMPANY_ID)
      }

      return Object.entries(filters).reduce((map, [key, filters]) => {
        if (!filtersToRemove.includes(key)) {
          map[key] = filters
        }

        return map
      }, {})
    },

    /**
     * @returns {object}
     */
    filtersToDisplay () {
      // Specific case for Fabrics:
      // remove the weight filters since we have the slider
      if (this.libraryType === LIBRARY_TYPE.FABRIC) {
        return Object.entries(this.visibleAvailableFilters).reduce(
          (map, [key, filters]) => {
            if (
              ![FILTER_TYPE.MIN_WEIGHT, FILTER_TYPE.MAX_WEIGHT].includes(key)
            ) {
              map[key] = filters
            }

            return map
          },
          {}
        )
      }

      return this.visibleAvailableFilters
    },

    /**
     * @returns {object}
     */
    availableFilterSettings () {
      return Object.keys(this.availableFilters).reduce((map, key) => {
        const settings = {
          isDisabled: false,
          disabledMessage: ''
        }

        const { TAGS, SEASON_ID } = FILTER_TYPE

        if (key === TAGS) {
          const seasonFilter = this.appliedFilters[SEASON_ID]

          settings.isDisabled = !seasonFilter || !seasonFilter[0]
          settings.disabledMessage = 'Select a season to filter by tags'
        }

        map[key] = settings

        return map
      }, {})
    },

    /**
     * @returns {boolean}
     */
    hasVisibleAvailableFilters () {
      return Object.keys(this.visibleAvailableFilters).length > 0
    },

    /**
     * @returns {object}
     */
    appliedFilters () {
      return this.getAppliedFilters()
    },

    /**
     * @returns {number}
     */
    appliedFiltersCount () {
      const appliedVisibleFilterValues = Object.entries(this.appliedFilters)
        .map(([key, value]) => (this.visibleAvailableFilters[key] ? value : []))
        .flat()

      return appliedVisibleFilterValues.length
    }
  },

  watch: {
    /**
     */
    availableFilters () {
      this.initializeFilters()
    },

    /**
     * @param {object} to
     * @param {object} from
     */
    $route (to, from) {
      const prevTeamId = from.query.group_id ?? from.query.division_id
      const currentTeamId = to.query.group_id ?? to.query.division_id

      // handles the case where going to stitches resets division_id and group_id both
      const areTeamsDefined =
        prevTeamId !== undefined && currentTeamId !== undefined

      // If team (group_id) was changed, clear all applied filters
      if (areTeamsDefined && prevTeamId !== currentTeamId) {
        this.clearFilters(true)
      }
    }
  },

  mounted () {
    this.initializeFilters()
    this.initializeFilterListBottomObserver()
  },

  beforeDestroy () {
    if (this.filterListIntersectionObserver) {
      this.filterListIntersectionObserver.disconnect()
    }
  },

  methods: {
    ...mapActions(['setAppliedFilter', 'resetAppliedFilters', 'resetItems']),

    /**
     */
    initializeFilterListBottomObserver () {
      this.filterListIntersectionObserver = new IntersectionObserver(
        this.toggleFilterListScrollTriggerVisibility,
        {
          root: this.$refs.filterContainer,
          threshold: 1
        }
      )
      this.filterListIntersectionObserver.observe(
        this.$refs.filterListBottomTrigger
      )
    },

    /**
     * @param {IntersectionObserverEntry[]} entries
     * @param {IntersectionObserver}        observer
     */
    toggleFilterListScrollTriggerVisibility (entries, observer) {
      this.$refs.filterListScrollIndicator.style.opacity = entries[0]
        .isIntersecting
        ? 0
        : 1
    },

    /**
     * @param   {number} weight
     * @returns {string}
     */
    formatWeightTooltip (weight) {
      return `${weight} G/Sqm`
    },

    /**
     *
     * @param {Array} weightRange
     */
    onWeightFilterChange (weightRange) {
      this.setAppliedFilter({
        filterGroupName: FILTER_TYPE.MIN_WEIGHT,
        filterIds: [weightRange[0]]
      })

      this.setAppliedFilter({
        filterGroupName: FILTER_TYPE.MAX_WEIGHT,
        filterIds: [weightRange[1]]
      })

      this.setPersistentFilters({
        libraryType: this.libraryType,
        filterGroupName: FILTER_TYPE.MIN_WEIGHT,
        value: weightRange[0]
      })

      this.setPersistentFilters({
        libraryType: this.libraryType,
        filterGroupName: FILTER_TYPE.MAX_WEIGHT,
        value: weightRange[1]
      })

      this.resetItems()

      this.$emit('filter-library')
    },

    /**
     * @param   {string} filterName
     *
     * @returns {string}
     */
    parseFilterGroupName (filterName) {
      const filterDisplayNameMap = {
        visible: 'visibility',
        sustainability_rating_id: 'sustainability',
        qc_passed: 'Quality Check',

        // if library is block, translate 'type' to 'block/shape'
        ...(this.libraryType === LIBRARY_TYPE.BLOCK
          ? { type: 'block/shape' }
          : {})
      }
      filterName = filterDisplayNameMap[filterName] || filterName

      const parsedName = filterName.split('_')

      if (filterName.endsWith('_id')) {
        parsedName.pop()
      }

      return parsedName.join(' ').trim()
    },

    /**
     */
    initializeFilters () {
      const availableFilters = this.availableFilters
      const appliedFilters = this.appliedFilters

      const persistentFilters = this.getPersistentFilters(this.libraryType)

      if (!availableFilters) {
        return
      }

      if (this.libraryType === LIBRARY_TYPE.FABRIC) {
        this.initWeightRange()
      }

      const tempFilters = {}
      const changedValue = {}
      for (const key in availableFilters) {
        let filterValue = []
        let initialValueWasChanged = false

        if (isNullOrUndefined(appliedFilters[key]) === false) {
          filterValue = appliedFilters[key] || []
        }

        if (isNullOrUndefined(persistentFilters[key]) === false) {
          filterValue = persistentFilters[key]

          if (
            arraysContainTheSame(appliedFilters[key], filterValue) === false
          ) {
            initialValueWasChanged = true
          }
        }

        tempFilters[key] = filterValue

        if (initialValueWasChanged === true) {
          changedValue[key] = filterValue
        }
      }

      this.filters = tempFilters

      for (const key in changedValue) {
        this.onCheckboxChange(key, changedValue[key])
      }

      // Need to (re)build the url in case switching of section and data comes from the store
      Object.keys(this.filters).forEach(filterKey => {
        this.setPersistentFilters({
          libraryType: this.libraryType,
          filterGroupName: filterKey,
          value: this.filters[filterKey]
        })
      })

      // HELPERS
      /**
       * @param   {*}       value
       *
       * @returns {boolean}
       */
      function isNullOrUndefined (value) {
        return value === null || value === undefined
      }

      /**
       * @param   {*[]}     arrayA
       * @param   {*[]}     arrayB
       *
       * @returns {boolean}
       */
      function arraysContainTheSame (arrayA, arrayB) {
        if (!Array.isArray(arrayA) || !Array.isArray(arrayB)) {
          return false
        }

        const tempArrayA = JSON.stringify([...arrayA].sort())
        const tempArrayB = JSON.stringify([...arrayB].sort())

        return tempArrayA === tempArrayB
      }
    },

    /**
     *
     */
    initWeightRange () {
      this.minWeight = Math.round(
        this.availableFilters[FILTER_TYPE.MIN_WEIGHT][0].value
      )
      this.maxWeight = Math.round(
        this.availableFilters[FILTER_TYPE.MAX_WEIGHT][0].value
      )

      if (
        FILTER_TYPE.MIN_WEIGHT in this.appliedFilters &&
        this.appliedFilters[FILTER_TYPE.MIN_WEIGHT].length > 0 &&
        FILTER_TYPE.MAX_WEIGHT in this.appliedFilters &&
        this.appliedFilters[FILTER_TYPE.MAX_WEIGHT].length > 0
      ) {
        this.weightFilterRange = [
          this.appliedFilters[FILTER_TYPE.MIN_WEIGHT][0],
          this.appliedFilters[FILTER_TYPE.MAX_WEIGHT][0]
        ]
      } else {
        this.weightFilterRange = [this.minWeight, this.maxWeight]
      }
    },

    /**
     * @param {string}   filterGroupName
     * @param {number[]} filterIds
     */
    onCheckboxChange (filterGroupName, filterIds) {
      this.setAppliedFilter({
        filterGroupName,
        filterIds
      })

      this.setPersistentFilters({
        libraryType: this.libraryType,
        filterGroupName,
        value: this.filters[filterGroupName]
      })

      this.resetItems()

      this.$emit('filter-library')
    },

    /**
     * @param {boolean} shouldResetAll
     */
    clearFilters (shouldResetAll = false) {
      const needsReset = Object.values(this.appliedFilters).some(
        filter => filter.length > 0
      )

      if (!needsReset) {
        return
      }

      const filtersToReset = []
      for (const key in this.filters) {
        if (
          this.canAccessWorkspaces ||
          ![FILTER_TYPE.GROUP_ID, FILTER_TYPE.DIVISION_ID].includes(key)
        ) {
          this.filters[key] = []
          filtersToReset.push(key)
        }
      }

      if (this.libraryType === LIBRARY_TYPE.FABRIC) {
        const MIN_WEIGHT_VALUE = 0
        const MAX_WEIGHT_VALUE = 1000
        this.weightFilterRange = [MIN_WEIGHT_VALUE, MAX_WEIGHT_VALUE]
      }

      if (shouldResetAll) {
        Object.values(LIBRARY_TYPE).forEach(libraryType => {
          this.resetAppliedFilters({
            // reset everything except team filter
            filtersToKeep: [FILTER_TYPE.GROUP_ID, FILTER_TYPE.DIVISION_ID],
            libraryType
          })
          this.resetPersistentFilters(libraryType)
        })
      } else {
        this.resetAppliedFilters({ filtersToReset })
        this.resetPersistentFilters(this.libraryType)
      }

      this.resetItems()

      this.$emit('filter-library')
    }
  }
}
</script>

<style lang="scss" scoped>
$filter-option-tag-max-width: 83%;
$font-size-icon-more-filters: 18px;
$filter-weight-slider-width: 90%;

.the-aside-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $grey-dark;
  font-family: $primary-font;
}

.the-aside-right__header,
.the-aside-right__footer {
  padding: spacing(2);
}

.the-aside-right__clear-filters {
  i {
    position: relative;
    top: spacing(1/8);
  }
}

.the-aside-right__title {
  text-transform: capitalize;
}

.the-aside-right__applied-filters-badge {
  display: inline-flex;

  /deep/ .el-badge__content {
    line-height: spacing(2);
  }
}

.the-aside-right__content {
  margin-bottom: auto;
  padding: 0 spacing(2);
  overflow-y: auto;
}

.the-aside-right__label {
  @include text-label;

  margin-bottom: spacing(1/2);
}

.the-aside-right__weight-slider {
  width: $filter-weight-slider-width;
  margin-right: auto;
  margin-left: auto;
}

// OVERRIDE CSS FROM ELEMENTUI
.the-aside-right__filter-group {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing(2);
  text-transform: capitalize;

  &:last-of-type {
    margin-bottom: 0;
  }

  /deep/ .el-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
  }

  /deep/ .el-select__tags-text {
    @include text-ellipsis;

    max-width: $filter-option-tag-max-width;
  }
}

.the-aside-right__footer {
  padding: spacing(2);
  font-size: $font-size-icon-more-filters;
  text-align: center;

  i {
    opacity: 1;
    transition: opacity 0.1s ease-in;
  }
}

.el-select {
  width: 100%;

  .el-select-dropdown__wrap .el-scrollbar__wrap {
    overflow: hidden;
  }
}
</style>
