<template>
  <div>
    <ElementButton
      v-if="item && canUserAccessStyleComments"
      type="text"
      :class="[{ 'style-comments__button--unread': hasUnreadEvents }]"
      @click="toggleComments"
    >
      <span
        v-if="hasUnreadEvents"
        class="library-card-item__unread-notifications-icon"
      >
        <ElementBadge
          :value="item.events.unreadNotificationsCount"
          :max="maxUnreadEventsCount"
          class="style-comments__badge"
        >
          <i class="el-icon-chat-dot-round" />
        </ElementBadge>
      </span>
      View Comments
    </ElementButton>
    <StitchDialog
      v-if="item && showComments"
      class="style-comments"
      visible
      append-to-body
      fullscreen
      :before-close="checkCommentField"
      @close="closeComments"
    >
      <StyleCommentsHeader />
      <div class="style-comments__events">
        <div
          :class="[
            'style-comments__events--container',
            { 'style-comments__events--content': hasStyleEvents }
          ]"
        >
          <StitchLoader v-if="isLoading" />
          <template v-else-if="hasStyleEvents">
            <StyleCommentsEvent
              v-for="styleEvent in styleEvents"
              :key="styleEvent.id"
              ref="events"
              :item="styleEvent"
              :show-controls="shouldShowStyleEventControls(styleEvent)"
              :is-faded="isStyleEventFaded(styleEvent)"
              @update-comment="onUpdateComment"
              @delete-comment="onDeleteComment"
            />
          </template>

          <div
            v-else
            class="style-comments__events--empty"
          >
            <h1>🤷‍♀️</h1>
            <p>There are no comments just yet.</p>
          </div>
        </div>
        <div class="style-comments__footer">
          <form
            class="style-comments__new-comment"
            @submit.prevent="processComment"
          >
            <Mentionable
              :keys="['@']"
              :items="mentionableUsers"
              :limit="10"
              insert-space
              omit-key
              class="new-comment__mentionable"
            >
              <textarea
                ref="inputField"
                v-model="formText"
                :disabled="isProcessingComment"
                placeholder="Type a comment..."
                :style="inputFieldStyle"
                class="new-comment__field"
                @keydown="handleKeyDown"
              />
            </Mentionable>
            <div class="comment-actions-row">
              <!-- Left: Attach file -->
              <div class="left-action">
                <ElementUpload
                  ref="upload"
                  action=""
                  :auto-upload="false"
                  :show-file-list="false"
                  :limit="1"
                  :on-exceed="handleExceedLimit"
                  :on-change="handleFileChange"
                  :on-remove="clearFiles"
                  :accept="acceptedExtensions"
                  class="upload-container"
                >
                  <ElementButton
                    :disabled="isCommentUpdating()"
                    type="info"
                    plain
                    size="mini"
                    class="new-comment__upload"
                  >
                    Attach file
                  </ElementButton>
                </ElementUpload>
                <span
                  v-if="chosenFile"
                  class="chosen-file"
                >
                  <i class="el-icon-document" />
                  {{ truncatedFileName }}
                  <ElementButton
                    type="text"
                    icon="el-icon-close"
                    class="file-remove"
                    @click="clearFiles"
                  />
                </span>
              </div>
              <!-- Right: Post & Clear buttons -->
              <div class="right-action">
                <ElementButton
                  v-if="commentId"
                  type="text"
                  icon="el-icon-close"
                  class="new-comment__clear"
                  @click="clearFormState"
                />
                <ElementButton
                  :disabled="!canSubmit"
                  type="primary"
                  plain
                  size="mini"
                  native-type="submit"
                  class="new-comment__submit"
                >
                  {{ buttonText }}
                </ElementButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StitchDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { KEYBOARD_KEY } from '@/constants/keyboardKey'
import calcTextareaHeight from 'element-ui/packages/input/src/calcTextareaHeight'
import { ItemShape } from '@/types'
import StyleCommentsEvent from './components/StyleCommentsEvent'
import StyleCommentsHeader from './components/StyleCommentsHeader'
import FeatureFlags from '@/services/featureFlags'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'

const messageDurationInMs = 10000
const maxSizeInMB = 100

export default {
  name: 'StyleComments',

  components: {
    StyleCommentsEvent,
    StyleCommentsHeader,
    Mentionable
  },

  props: {
    item: ItemShape.loose.isRequired
  },

  data () {
    return {
      isLoading: false,
      isLoadingUsers: false,
      isProcessingComment: false,
      inputFieldStyle: {},
      inputFieldSettings: {
        minLines: 2,
        maxLines: 12
      },
      formText: null,
      commentId: null,
      pollingInterval: null,
      pollingTiming: 5000,
      toastStrings: {
        COMMENT_UPDATED: 'Comment updated 👌',
        COMMENT_DELETED: 'Comment deleted 🔥'
      },
      toastDuration: 3000,
      showComments: false,
      maxUnreadEventsCount: 99,
      chosenFile: null
    }
  },

  computed: {
    ...mapGetters(['getStyleEvents']),

    ...mapGetters({
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {string}
     */
    truncatedFileName () {
      if (!this.chosenFile) {
        return ''
      }

      const maxNrOfChar = 25
      const fullName = this.chosenFile.name || ''

      return fullName.length > maxNrOfChar
        ? fullName.substring(0, maxNrOfChar) + '...'
        : fullName
    },

    /**
     * @returns {string}
     */
    acceptedExtensions () {
      const acceptedImages = [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.bmp',
        '.tiff',
        '.webp'
      ]
      const acceptedDocuments = [
        '.pdf',
        '.doc',
        '.docx',
        '.txt',
        '.xls',
        '.xlsx',
        '.csv',
        '.ppt',
        '.pptx'
      ]
      const acceptedArchives = ['.zip', '.rar', '.tar', '.gz']

      return [
        ...acceptedImages,
        ...acceptedDocuments,
        ...acceptedArchives
      ].join(',')
    },

    /**
     * @returns {object[]}
     */
    styleEvents () {
      return [...this.getStyleEvents].reverse()
    },

    /**
     * @returns {boolean}
     */
    hasAttachedFile () {
      return !!this.chosenFile
    },

    /**
     * @returns {boolean}
     */
    hasStyleEvents () {
      return this.styleEvents.length > 0
    },

    /**
     * @returns {boolean}
     */
    isWritingComment () {
      return this.formText && this.formText.length > 0
    },

    /**
     * @returns {boolean}
     */
    canSubmit () {
      return (
        this.hasAttachedFile ||
        (this.isWritingComment && this.isProcessingComment === false)
      )
    },

    /**
     * @returns {string}
     */
    buttonText () {
      return this.commentId ? 'Edit' : 'Post'
    },

    /**
     * @returns {boolean}
     */
    canUserAccessStyleComments () {
      return FeatureFlags.canUserAccessStyleComments()
    },

    /**
     * @returns {boolean}
     */
    hasUnreadEvents () {
      return this.item.events.unreadNotificationsCount > 0
    },

    /**
     * @returns {Array}
     */
    mentionableUsers () {
      return this.item.mentionableUsers.map(user => {
        return {
          value: user.email,
          label: user.email
        }
      })
    }
  },

  watch: {
    /**
     */
    formText () {
      this.resizeInputField()
    },

    /**
     */
    showComments () {
      if (this.showComments) {
        this.initializeStyleComments()
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.pollingInterval)
  },

  async created () {
    if (this.item) {
      this.isLoadingUsers = true
      await this.fetchMentionableUsers({ teamId: this.item.groupId })
      this.isLoadingUsers = false
    }
  },

  methods: {
    ...mapActions([
      'fetchStyleEvents',
      'addStyleComment',
      'updateStyleComment',
      'deleteStyleComment',
      'resetStyleUnreadNotificationsCount',
      'setStyleEventsFilter',
      'fetchMentionableUsers'
    ]),

    /**
     */
    async initializeStyleComments () {
      this.isLoading = true

      this.resizeInputField()

      const payload = {
        styleId: this.item.id
      }

      await Promise.all([
        this.resetStyleUnreadNotificationsCount(payload),
        this.fetchStyleEvents({ styleId: this.item.id, forceReset: true })
      ])

      this.isLoading = false

      await this.$nextTick()
      this.scrollToLastEvent()
      this.initializePolling()
    },

    /**
     */
    initializePolling () {
      clearInterval(this.pollingInterval)

      this.pollingInterval = setInterval(
        async () => {
          await this.fetchStyleEvents({ styleId: this.item.id })
        },
        this.pollingTiming,
        this.item
      )
    },

    /**
     * Checks if the provided file exceeds the maximum allowed file size.
     *
     * @param   {File}    file - The file object to validate
     * @returns {boolean}      - Returns true if the file is larger than max, false otherwise
     */
    checkFileSize (file) {
      return file.size > maxSizeInMB * 1024 * 1024
    },

    /**
     * Handles the file selected by the user from the upload component.
     *
     * @param {object} file - The file object emitted by ElementUpload.
     */
    handleFileChange (file) {
      if (this.checkFileSize(file)) {
        this.$message.error({
          message: `File size should not exceed ${maxSizeInMB} MB!`,
          duration: messageDurationInMs,
          showClose: true,
          onClose: this.focusInputField()
        })
        this.clearFiles()

        return
      }

      this.chosenFile = file.raw
      this.focusInputField()
    },

    /**
     * Triggered when the number of selected files exceeds the allowed limit.
     */
    handleExceedLimit () {
      this.$message.warning({
        message:
          'Only 1 file can be added per comment.\n' +
          'If you made a mistake and want to change it, ' +
          'click the X button next to the file name to remove it and add another one.',
        duration: messageDurationInMs,
        showClose: true,
        onClose: this.focusInputField()
      })
    },

    /**
     */
    clearFiles () {
      this.chosenFile = null

      // Clear the ElementUpload file selection
      if (
        this.$refs.upload &&
        typeof this.$refs.upload.clearFiles === 'function'
      ) {
        this.$refs.upload.clearFiles()
      }

      this.focusInputField()
    },

    /**
     */
    async resizeInputField () {
      await this.$nextTick()

      if (this.$refs.inputField) {
        this.inputFieldStyle = calcTextareaHeight(
          this.$refs.inputField,
          this.inputFieldSettings.minLines,
          this.inputFieldSettings.maxLines
        )
      }
    },

    /**
     * @param {Event} event
     */
    handleKeyDown (event) {
      if (KEYBOARD_KEY.ENTER.includes(event.key) && event.shiftKey) {
        event.preventDefault()
        this.processComment()
      }
    },

    /**
     */
    async processComment () {
      if (!this.canSubmit) {
        return
      }

      this.isProcessingComment = true

      let trimmedText = this.formText?.trim() || null

      if (!trimmedText) {
        trimmedText = '-' // Set '-' as default if the comment is empty
      }

      const payload = {
        styleId: this.item.id,
        text: trimmedText
      }

      if (this.commentId) {
        // Edit existing comment
        payload.commentId = this.commentId

        await this.updateStyleComment(payload)
        this.showToast(this.toastStrings.COMMENT_UPDATED)
      } else {
        // Post a new comment
        if (this.hasAttachedFile) {
          payload.attachmentFile = this.chosenFile
        }

        await this.addStyleComment(payload)
      }

      this.scrollToLastEvent()
      this.clearFormState()
      this.isProcessingComment = false
      this.focusInputField()
    },

    /**
     */
    scrollToLastEvent () {
      if (!this.hasStyleEvents) {
        return
      }

      this.$nextTick().then(() => {
        const latestEvent = this.$refs.events[this.$refs.events.length - 1]

        if (!latestEvent || !latestEvent.$el) {
          return
        }

        latestEvent.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      })
    },

    /**
     * @param   {object}  styleEvent
     *
     * @returns {boolean}
     */
    shouldShowStyleEventControls (styleEvent) {
      if (this.commentId) {
        return false
      }

      if (!styleEvent.comment) {
        return false
      }

      return styleEvent.comment.contributor.email === this.user.email
    },

    /**
     * @returns {boolean}
     */
    isCommentUpdating () {
      if (this.commentId) {
        return true
      }
    },

    /**
     * @param   {object}  styleEvent
     *
     * @returns {boolean}
     */
    isStyleEventFaded (styleEvent) {
      if (!this.commentId) {
        return false
      }

      if (!styleEvent.comment) {
        return true
      }

      return styleEvent.comment.id !== this.commentId
    },

    /**
     * @param {object} payload
     * @param {number} payload.commentId
     * @param {string} payload.text
     */
    onUpdateComment ({ commentId, text }) {
      this.commentId = commentId
      this.formText = text

      this.focusInputField()
    },

    /**
     * @param {boolean} [focusInputField=true]
     */
    clearFormState (focusInputField = true) {
      this.commentId = null
      this.formText = null

      if (focusInputField) {
        this.focusInputField()
      }

      // Also clear the file selection
      this.clearFiles()
    },

    /**
     */
    async focusInputField () {
      await this.$nextTick()

      this.$refs.inputField.focus()
    },

    /**
     * @param {object} payload
     * @param {number} payload.commentId
     */
    onDeleteComment ({ commentId }) {
      this.$confirm(
        'If you delete the comment, there will be no going back 😬',
        'Delete comment?',
        {
          confirmButtonText: 'Delete it!',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(async () => {
          await this.deleteStyleComment({
            styleId: this.item.id,
            commentId
          })
          this.showToast(this.toastStrings.COMMENT_DELETED)
        })
        .catch(() => {})
    },

    /**
     * @param {string} toastString
     */
    showToast (toastString) {
      this.$message({
        message: toastString,
        type: 'success',
        showClose: true,
        duration: this.toastDuration
      })
    },

    /**
     */
    closeComments () {
      this.showComments = false
      this.clearFormState(false)
    },

    /**
     */
    toggleComments () {
      this.showComments = !this.showComments

      if (this.showComments) {
        this.$tracking.trackEvent({
          object: TRACKER_OBJECTS.COMMENT,
          action: TRACKER_EVENTS.VIEWED,
          item: this.item
        })
      }
    },

    /**
     * @param {Function} done
     */
    checkCommentField (done) {
      if (!this.isWritingComment) {
        done()

        return
      }

      this.$confirm(
        `If you quit now the ${
          this.commentId ? 'changes to the comment' : "comment you're writing"
        } will be lost.`,
        `Quit ${this.commentId ? 'Editing' : 'Comment'}`,
        {
          confirmButtonText: 'Quit',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          done()
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
$border-radius-form: $border-radius-m;
$rate-border-radius: 49px;
$badge-margin-right: 10px;

.style-comments__button--unread {
  color: $red;
}

.style-comments__badge {
  display: inline-flex;
  margin-right: $badge-margin-right;

  /deep/ .el-badge__content {
    line-height: spacing(2);
    background-color: $red;
  }
}

.style-comments {
  padding: spacing(4) spacing(16);
  overflow-y: hidden;

  /deep/ .el-dialog {
    max-width: spacing(80);
    margin: auto;
    padding: 0;
  }

  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.style-comments__events {
  @include flex-center(column);

  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}

.style-comments__events--container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: spacing(1) spacing(4) 0 spacing(4);
  overflow-y: auto;
}

.style-comments__events--content {
  justify-content: flex-start;
}

.style-comments__events--empty {
  text-align: center;
}

.style-comments__footer {
  width: 100%;
  padding: spacing(2) spacing(4);
  border-top: $border-divider;
}

.style-comments__new-comment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: spacing(1/2);
  background-color: $grey-ultra-light;
  border-radius: $border-radius-form;
}

.new-comment__mentionable {
  width: 100%;
}

.new-comment__field {
  box-sizing: border-box;
  width: 100%;
  padding: 0 spacing(1);
  background-color: transparent;
  resize: none;
}

/deep/ .vm-textarea,
/deep/ .mention-textarea {
  padding-right: spacing(1) !important;
}

.new-comment__clear {
  padding: spacing(1/2) 0;
  color: $grey;
}

.comment-actions-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: spacing(0.5);
}

.left-action,
.right-action {
  display: flex;
  align-items: center;
}

.new-comment__upload {
  margin-right: $border-radius-s;
  font-size: $font-size-label;
}

.new-comment__upload:hover {
  background-color: $blue;
  border-color: $blue;
}

.chosen-file {
  display: inline-flex;
  align-items: center;
  margin-left: spacing(1);
  color: $grey-light;
  font-size: $font-size-label;
}

.chosen-file .el-icon-document {
  margin-right: $border-radius-s;
  font-size: $font-size-label;
}

.file-remove {
  margin-left: spacing(1/2);
  color: $grey;
}

.new-comment__submit {
  width: spacing(10);
}
</style>

<style lang="scss">
$mention-padding: 4px 10px;
$mention-border-radius: 4px;

.mention-item {
  padding: $mention-padding;
  border-radius: $mention-border-radius;
}

.mention-selected {
  background: $blue-light;
}
</style>
