var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElementCard',{class:[
    'library-card-item',
    {
      'library-card-item--disabled': _vm.isUploadingOrFailed
    }
  ],attrs:{"body-style":{ padding: '0px' },"shadow":_vm.isHovered ? 'always' : 'never',"title":_vm.processedItem.name}},[_c('div',{staticClass:"library-card-item-preview",on:{"mouseleave":function($event){return _vm.handleCardHover(false)},"mouseover":function($event){return _vm.handleCardHover(true)},"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleCardContextMenu.apply(null, arguments)},"click":function($event){$event.stopPropagation();return _vm.handleCardClick.apply(null, arguments)}}},[_c('div',{staticClass:"library-card-item__image-group"},[_c('div',{class:[
          'library-card-item__status-overlay',
          {
            'status--upload-pending':
              _vm.processedItem.status === _vm.UPLOAD_STATUS.PENDING
          },
          {
            'status--upload-failed':
              _vm.processedItem.status === _vm.JOB_STATUS.FAILED ||
              _vm.processedItem.status === _vm.UPLOAD_STATUS.FAILED
          },
          {
            'status--job-prepare-download':
              _vm.processedItem.status === _vm.JOB_STATUS.DOWNLOAD
          },
          {
            'status--job-started':
              _vm.processedItem.status === _vm.JOB_STATUS.STARTED ||
              _vm.processedItem.status === _vm.JOB_STATUS.PENDING
          },
          {
            'status--job-created': _vm.processedItem.status === _vm.JOB_STATUS.CREATED
          }
        ]},[(_vm.isRunningJob)?_c('div',{staticClass:"library-card-item__status-overlay-icon"},[_c('StitchLoader')],1):_vm._e()]),(_vm.processedItem.libraryType === _vm.LIBRARY_TYPE.JOB)?_c('div',{staticClass:"library-card-item__image-multiple"},_vm._l((_vm.multipleThumbnailUrl),function(url,thumbnailIndex){return _c('div',{key:thumbnailIndex,staticClass:"image",style:({ backgroundImage: ("url('" + url + "')") })})}),0):(_vm.thumbnailUrl)?_c('div',{class:[
          'library-card-item__image image',
          {
            'library-card-item__image--fabrics':
              _vm.processedItem.libraryType === _vm.LIBRARY_TYPE.FABRIC
          }
        ],style:({
          backgroundImage: ("url('" + _vm.thumbnailUrl + "')")
        })}):_c('div',{class:[
          'library-card-item__image image placeholder',
          {
            'placeholder--preparing':
              _vm.shouldShowPreparingThumbnail(_vm.processedItem)
          }
        ]},[(_vm.shouldShowPreparingThumbnail(_vm.processedItem))?_c('div',{staticClass:"preparing-thumbnail"},[_c('StitchLoader'),_vm._v(" Preparing Thumbnail ")],1):_vm._e()])]),_c('div',{staticClass:"library-card-item__info"},[_c('div',{staticClass:"library-card-item__info-title"},[_vm._v(" "+_vm._s(_vm.processedItem.name)+" ")]),_c('div',{staticClass:"library-card-item__info-sub-title"},[(
            _vm.processedItem.libraryType === _vm.LIBRARY_TYPE.FABRIC &&
              _vm.sustainabilityRatingValue
          )?_c('div',{staticClass:"library-card-item__rate"},_vm._l((_vm.sustainabilityRatingValue),function(counter){return _c('span',{key:counter,staticClass:"library-card-item__rate-icon"})}),0):_vm._e(),_c('div',{staticClass:"library-card-item__message"},[_c('span',[_vm._v(_vm._s(_vm.itemMessage))])]),(_vm.processedItem.libraryType === _vm.LIBRARY_TYPE.JOB)?_c('span',[_vm._v(" "+_vm._s(_vm.itemCreatedAt)+" ")]):_vm._e()]),(
          _vm.processedItem.libraryType === _vm.LIBRARY_TYPE.FABRIC &&
            _vm.processedItem.weight
        )?_c('div',{staticClass:"library-card-item__weight"},[_vm._v(" "+_vm._s(_vm.processedItem.weight)+" g/sqm ")]):_vm._e(),(_vm.processedItem.libraryType === _vm.LIBRARY_TYPE.STYLE)?_c('div',{staticClass:"library-card-item__info-sub-title"},[(_vm.isLoadingStatus)?[_c('StitchLoader',{attrs:{"size":"mini","placement":"start"}})]:[_c('LibraryStatusSelector',{attrs:{"size":"small","item":_vm.processedItem,"transitions":_vm.directStatusTransitions,"reset":_vm.showStyleStatusActionForm},on:{"update-status":_vm.updateStatus}})],_c('FormGenerator',{attrs:{"show-form-generator":_vm.showStyleStatusActionForm,"form-configuration":_vm.styleStatusActionFormConfiguration,"additional-data":_vm.processedItem,"original-data":_vm.actionConfirmationFormData,"default-data":_vm.styleStatusActionDefaultFormData,"options":_vm.styleStatusActionReasons,"is-creation":true},on:{"form-close":_vm.onStyleStatusActionFormClose,"form-complete":_vm.completeStyleStatusActionForm}}),_c('div',[_c('span',{staticClass:"library-card-item__quality-check-icon"},[(_vm.processedItem.qualityCheck === null)?_c('i',{staticClass:"el-icon-remove not-set"}):(_vm.processedItem.qualityCheck)?_c('i',{staticClass:"el-icon-success approved"}):_c('i',{staticClass:"el-icon-error rejected"})]),(_vm.processedItem.unreadNotificationsCount > 0)?_c('span',{staticClass:"library-card-item__unread-notifications-icon"},[_c('ElementBadge',{staticClass:"library-card-item__unread-notifications-badge",attrs:{"value":_vm.processedItem.unreadNotificationsCount,"hidden":_vm.processedItem.unreadNotificationsCount === 0}},[_c('i',{staticClass:"el-icon-chat-dot-round"})])],1):_vm._e()])],2):_vm._e()]),_c('div',{staticClass:"library-card-item__actions"},[(
          !_vm.isWorkspaceLibraryItem &&
            _vm.hasModel3D(_vm.processedItem, 1, 2) &&
            _vm.isHovered
        )?_c('a',{ref:"downloadFile",on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile({ item: _vm.processedItem })}}},[(_vm.showDownloadSpinnerStatus !== _vm.processedItem.id)?_c('i',{staticClass:"el-icon-download"}):_c('StitchLoader',{attrs:{"size":"mini"}})],1):(
          !_vm.isWorkspaceLibraryItem && _vm.hasModel3D(_vm.processedItem) && _vm.isHovered
        )?_c('ElementDropdown',{on:{"command":_vm.downloadFile,"visible-change":_vm.handleFileDropdownVisibleChange}},[_c('a',{attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile({ item: _vm.processedItem })}}},[_c('i',{staticClass:"el-icon-download"})]),_c('ElementDropdownMenu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.processedItem.filesDict.model3D),function(value,index){return _c('ElementDropdownItem',{key:index,attrs:{"command":{ item: _vm.processedItem, fileIndex: index },"data-testid":("downloadItem" + index)}},[_vm._v(" "+_vm._s(value.description)+" ")])}),1)],1):_vm._e(),(_vm.processedItem.libraryType === _vm.LIBRARY_TYPE.JOB)?[(_vm.isRunningJob)?_c('span',{staticClass:"el-dropdown-link",on:{"click":function($event){$event.stopPropagation();return _vm.openConfirmDelete(_vm.processedItem)}}},[_c('i',{staticClass:"el-icon-close library-card-item__actions-delete library-card-item__actions-delete--icon"})]):_vm._e()]:_vm._e(),(_vm.showCardMenu)?_c('StitchDropdown',{attrs:{"append-to-body":true,"dropdown-items":_vm.getDropdownItems(_vm.processedItem),"icon-type":_vm.ICON_TYPE.MENU,"placement":_vm.DROPDOWN_PLACEMENT.BOTTOM_END,"show-button":false,"show-dropdown":_vm.showDropdown},on:{"visibility-change":_vm.handleVisibilityChange}}):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }